import React, { useEffect } from "react";
import "./App.css";

import {
  Box,
  Card,
  Loader,
  Page,
  WixDesignSystemProvider,
  EmptyState,
  Button,
  Badge,
  Layout,
  Cell,
  FormField,
  Input,
  TextButton,
  Dropdown,
  TableActionCell,
  Table,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import { ReactTagManager } from "react-gtm-ts";
import * as Icons from "@wix/wix-ui-icons-common";

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function App() {
  ReactTagManager.init(tagManagerArgs);
  const token = new URLSearchParams(window.location.search).get("token");
  const [screen, setScreen] = React.useState("");
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [settings, setSettings] = React.useState({
    options: [],
  });
  const instance = new URLSearchParams(window.location.search).get("instance");

  const [currentOption, setCurrentOption] = React.useState({
    name: "Standard Gift Wrapping",
    price: {
      amount: 5,
      taxable: true,
    },
  } as any);

  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  });

  useEffect(() => {
    setIntercomIdentity();
    setInstance();
    getSettings();
  }, []);

  const BASE_URL = `https://certifiedcode.wixsite.com/gift-wrapping/_functions`;

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.hash && data.email) {
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash,
          });
        }
      })
      .catch(() => {});
  }

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setInstanceData(data);
        setIsUpgraded(data.instance.isFree === false);
      })
      .catch(() => {});
  }

  function getSettings() {
    fetch(BASE_URL + "/settings", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setSettings(data);
      })
      .catch(() => {});
  }

  function saveSettings(data: any) {
    setIsSaving(true);
    console.log(data);
    fetch(BASE_URL + "/settings", {
      method: "POST",
      headers: {
        Authorization: instance || "",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        setSettings(data);
        setIsSaving(false);
      })
      .catch(() => {});
  }

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=07996954-68d9-4e0e-a38d-8f4a4e0b7802&redirectUrl=https://certifiedcode.wixsite.com/gift-wrapping/_functions/@certifiedcode/base-backend/auth`;
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text="Loading" />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  const OptionsTable = () => {
    const renderRowDetails = (row: any) => {
      if (row.expandable) {
        return <Box height="120px" />;
      }
    };

    const columns = [
      {
        title: "Name",
        render: (row: any) =>
          row.name
      },
      {
        title: "Price",
        render: (row: any) =>
         row.price.amount
      },
      {
        title: "Status",
        render: (row: any) => (
          <Badge
            skin={
              row.enabled === true
                ? "success"
                : "neutralLight"
            }
          >
             { row.enabled === true
              ? "ACTIVE"
              : "INACTIVE"}
          </Badge>
        ),
      },
      {
        title: "",
        render: (row: any) => (
          <TableActionCell
            size="small"
            primaryAction={{
              text: "Delete Option",
              onClick: () => {
                const options = settings.options || [];
                // @ts-ignore
                const index = options.indexOf(row);
                options.splice(index, 1);
                saveSettings({
                  ...settings,
                  options,
                });
              },
            }}
          />
        ),
      },
    ];

    return (
      <Box gap="SP2" direction="vertical">
        <Table
          rowDetails={(row: any) => renderRowDetails(row)}
          data={settings.options}
          columns={columns}
        >
          <Table.Content />
        </Table>
      </Box>
    );
  };

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Page height="100vh">
        <Page.Header
          title={screen === "" ? "Manage Gift Wrapping" : "Add Wrapping Option"}
          showBackButton={screen !== ""}
          onBackClicked={() => {
            setScreen("");
          }}
          actionsBar={
            <Box direction="horizontal" gap="SP2">
              <Button
                skin="dark"
                onClick={() => {
                  window.Intercom("showNewMessage");
                }}
              >
                Contact Support
              </Button>
              <Button
                skin="premium"
                disabled={isUpgraded}
                prefixIcon={<Icons.PremiumFilled />}
                onClick={() => {
                  window.open(
                    `https://www.wix.com/apps/upgrade/${"07996954-68d9-4e0e-a38d-8f4a4e0b7802"}?appInstanceId=${
                      (instanceData as any)["instance"]["instanceId"]
                    }`
                  );
                }}
              >
                {isUpgraded ? "Manage Plan" : "Upgrade to Premium"}
              </Button>
            </Box>
          }
        />
        <Page.Content>
          {screen === "" ? (
            <Card>
              <Card.Header
                title="Give gift wrapping options to customers"
                suffix={
                  <Button
                    size="small"
                    prefixIcon={<Icons.AddSmall />}
                    onClick={() => {
                      setScreen("add");
                    }}
                  >
                    Add Wrapping Option
                  </Button>
                }
              />
              {settings?.options?.length > 0 ? (
                OptionsTable()
              ) : (
                <Card.Content size="medium">
                  <EmptyState
                    theme="section"
                    title="Add your first gift wrapping option"
                    subtitle="Create a wrapping option for your customers to buy at checkout."
                  >
                    <TextButton
                      prefixIcon={<Icons.Add />}
                      onClick={() => {
                        setScreen("add");
                      }}
                    >
                      Add Wrapping Option
                    </TextButton>
                  </EmptyState>
                </Card.Content>
              )}
            </Card>
          ) : null}
          {screen === "add" ? (
            <Card>
              <Card.Header
                title="Wrapping Option"
                subtitle="Give a price and name to your wrapping option"
              />
              <Card.Divider />
              <Card.Content>
                <Layout>
                  <Cell span={8}>
                    <Box direction="vertical" gap="SP2">
                      <FormField label="What is the name of the option?">
                        <Layout>
                          <Cell span={8}>
                           <Input
                              placeholder="Standard Gift Wrapping"
                              value={currentOption.name}
                              onChange={(e) => {
                                setCurrentOption({
                                  ...currentOption,
                                  name: e.currentTarget.value,
                                });
                              }}
                            />
                          </Cell>
                        </Layout>
                      </FormField>
                      <FormField label="How much is it?">
                        <Layout>
                          <Cell span={8}>
                           <Input
                              placeholder="5"
                              min={0.01}
                              step={0.01}
                              value={currentOption.price.amount}
                              onChange={(e) => {
                                setCurrentOption({
                                  ...currentOption,
                                  price: {
                                    ...currentOption.price,
                                    amount: Number(e.currentTarget.value),
                                  },
                                });
                              }}
                            />
                          </Cell>
                        </Layout>
                      </FormField>
                      <Button
                        disabled={
                          currentOption.name === "" ||
                          currentOption.price.amount <= 0
                        }
                        prefixIcon={<Icons.Add />}
                        onClick={() => {
                          const options = settings.options || [];
                          // @ts-ignore
                          options.push(currentOption);
                          saveSettings({
                            ...settings,
                            options
                          });
                          setScreen("");
                        }}
                      >
                        Set Live
                      </Button>
                    </Box>
                  </Cell>
                </Layout>
              </Card.Content>
            </Card>
          ) : null}
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
}

export default App;
